<header class="section-header shadow-sm" *ngIf="service.color$" id="__top"
    [ngClass]="inScrollDesk ? 'show':'hide no-shadow'">
    <section class="header-main bg-white fixed-top">
        <div class="container">
            <div class="row align-items-center py-lg-2">
                <div class="col-1">
                    <a href="javascript:;" routerLink="/home" class="brand-wrap mb-0">
                        <img alt="{{merchant?.merchantName}}" src="{{merchant.logo}}">
                    </a>
                    <!-- brand-wrap.// -->
                </div>
                <div class="align-items-center justify-content-center sm-none">
                    <app-search-product [ngClass]="inScrollSearch ? 'show':'hide'"></app-search-product>
                </div>
                <!-- col.// -->
                <div class="col">
                    <div class="d-flex align-items-center justify-content-center" >
                        <!-- my account -->
                        <!-- <div *ngIf="isLogin" class="dropdown mr-4 sm-none">
                            <a href="#" class="dropdown-toggle text-dark py-3 d-block" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Olá {{user.first_name}},
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="javascript:;"
                                    (click)="functions.refresh('profile', 'order')">Meus Pedidos</a>
                                <a class="dropdown-item" href="javascript:;"
                                    (click)="functions.refresh('profile', 'account')">Minha Conta</a>
                                <a class="dropdown-item" href="javascript:;"
                                    (click)="functions.refresh('profile', 'address')">Meus endereços</a>
                                <a class="dropdown-item" href="javascript:;" (click)="logout()">Sair</a>
                            </div>
                        </div> -->
                        <!-- signin -->
                        <a href="javascript:;" (click)="openModalCart()" *ngIf="this.configService.configCatalog?.disableCheckout === '0'"
                            class="widget-header bg-primary rounded text-dark">
                            <div class="icon d-flex align-items-center">
                                <i class="feather-shopping-bag h6 mr-2 mb-0"></i>
                                <span *ngIf="cartService.cartCount > 0"
                                    class="badge-item-header position-absolute ml-2 text-white"><span
                                        class="small">{{cartService.cartCount}}</span></span>
                                <span class="pl-2">Cesta</span>
                            </div>
                        </a>
                    </div>
                    <!-- widgets-wrap.// -->
                </div>
                <!-- col.// -->
            </div>
            <!-- row.// -->
        </div>
        <!-- container.// -->
    </section>
    <!-- header-main .// -->
</header>


<div class="header-mobile" [ngClass]="inScrollMob ? 'show':'hide'">
    <div>
        <div class="header-mobile-logo">
            <a href="javascript:;" routerLink="/home">
                <img alt="{{merchant?.merchantName}}" class="" src="{{merchant.logo}}">
            </a>
        </div>
        <div>
            <app-search-product></app-search-product>
        </div>
    </div>
</div>
