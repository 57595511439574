<div class="modal-menu-product">
  <!-- recepie-header -->
  <form #formMenu="ngForm" *ngIf="!loading">

    <!-- recepie-body -->
    <div class="recepie-body modal-body-addons relative">
      <a class="bg-white btn-close-modal text-center" (click)="activeModal.close()"><span><i class="feather-x h5"></i></span></a>

      <div class="modal-menu-img px-3 pt-3">
        <figure class="m-0"> <img src="{{item.photo}}" alt="{{item.item_name}}">
          <small class="small text-muted m-0">*imagens ilustrativas</small>
        </figure>
      </div>

      <nav class="px-3 modal-header-title bg-white border-bottom border-bottom-secondary shadow-sm">
        <h5 class="font-weight-bold m-0">{{item.item_name}}</h5>
      </nav>
      <p class="text-muted m-0 p-3 ">{{item.item_description}}</p>

      <!-- SIZE options - adicionado uma nova classe
         .product-size-option e na verificação quando não for preenchido, adiciona a classe .size-error
         -->
      <div class="px-3 product-size-option" id="price-content" >
        <div *ngIf="item.prices_new !== null && item.prices_new.length ; else priceOldDiv">
          <h6 class="custom-control custom-radio pl-4 p-2 m-0" *ngFor="let price of item.prices_new; let i = index ">
            <!--INPUT PRICE-->

            <input
                    #priceInput
                    type="radio"
                    [id]="'price-'+price.priceId"
                    name="price"
                    class="custom-control-input"
                    [value]="verifyHaveSizeNamePrice(price) ? price.value+'|'+price.priceName+'|'+price.sizeId+'|'+price.discount : price.isDiscount && price.isDiscount > 0 ? price.discount : price.value "
                    (change)="selectPrice(priceInput.value)"
                    [checked]="verifyCheckPrice(priceInput.value)"
            >
            <label class="custom-control-label pt-1" [for]="'price-' + price.priceId">
              {{price.priceName ? price.priceName : ''}}
              <!--PREÇO ORIGINAL RISCADO SE TIVER DESCONTO-->
              <span class="normal-price text-muted ml-2 small" [style.display]="price.isDiscount && price.isDiscount > 0 ? '' : 'none'" > {{price.value | prettyPrice}}</span>
              <!--PREÇO DO ITEM-->
              <span class="text-muted ml-2" *ngIf="price.isDiscount && price.isDiscount > 0; else priceNormal">
                {{price.discount | prettyPrice}}
              </span>
              <ng-template #priceNormal>
                <span class="text-muted ml-2">
                {{price.value | prettyPrice}}
              </span>
              </ng-template>

            </label>
          </h6>
        </div>


        <ng-template class="" #priceOldDiv>
          <h6 class="custom-control custom-radio pl-4 p-2 m-0" *ngFor="let price of item.prices; let i = index ">
            <!--INPUT PRICE-->

            <input
                    #priceInput
                    type="radio"
                    [id]="'price-'+price.size_id"
                    name="price"
                    class="custom-control-input"
                    [value]="item.prices.length > 1 ? price.price+'|'+price.size+'|'+price.size_id : price.discount_price ? price.discount_price : price.price "
                    (change)="selectPrice(priceInput.value)"
                    [checked]="verifyCheckPrice(priceInput.value)"
            >
            <label class="custom-control-label pt-1" [for]="'price-' + price.size_id">
              {{price.size ? price.size : ''}}
              <!--PREÇO ORIGINAL RISCADO SE TIVER DESCONTO-->
              <span class="normal-price text-muted ml-2 small" [style.display]="price.discount_price ? '' : 'none'" > {{price.price | prettyPrice}}</span>
              <!--PREÇO DO ITEM-->
              <span class="text-muted ml-2" *ngIf="price.discount_price; else priceNormal">
                {{price.discount_price | prettyPrice}}
              </span>
              <ng-template #priceNormal>
                <span class="text-muted ml-2">
                {{price.price | prettyPrice}}
              </span>
              </ng-template>

            </label>
          </h6>
        </ng-template>

      </div>

      <input type='hidden' name="category_id" id="category_id" [ngModel]="catId">
      <input type='hidden' name="item_id" id="item_id"  [ngModel]="itemId">
      <input type='hidden' name="two_flavors" id="two_flavors" ngModel="0">
      <div *ngIf="item.addon_item" id="__addonList" name="__addonList" class="scroller">
        <div class="border-top product-addons-option" *ngFor="let addon of item.addon_item">
          <div id="__{{addon.subcat_name | replaceAll}}"></div>
          <input *ngIf="addon.min" type="hidden" [value]="addon.min" class="minInput" >
          <!--NOME CATEGORIA STICKY-->
          <h6
                  class="font-weight-bold bg-light p-3 category-addons"
                  id="name__{{addon.subcat_name | replaceAll}}"
          >
            {{addon.subcat_name}} <br>
            <small>
              <!-- OBRIGATORIO-->
              <span *ngIf="addon.require_addons == '2' || addon.min === '1'; else opcional">SELEÇÃO OBRIGATÓRIA (MÍNIMO {{addon.min}}) </span>
              <!--OPCIONAL -->
              <ng-template #opcional>SELEÇÃO OPCIONAL </ng-template>

              <span class="text-min-max"> {{totalAddonQty(addon.subcat_id)}} </span>
              <span class="text-min-max"> {{addon.max ? ' / ' + addon.max : ''}} </span>

            </small>
          </h6>

          <input *ngIf="addon.require_addons == '2'" type="hidden" name="require_addons" class="require_addons" [value]="addon.require_addons" [attr.data-subcat_id]="addon.subcat_id" [attr.data-subcat_name]="'__' + addon.subcat_name | replaceAll" [attr.data-multi_option]="addon.multi_option">
          <input *ngIf="getValueCalculateType(addon.subcat_id) !== '' "  type="hidden" [name]="getValueCalculateType(addon.subcat_id)" class="calculate_type" [value]="getValueCalculateType(addon.subcat_id)" [attr.data-subcat_id]="addon.subcat_id" [attr.data-subcat_name]="'__' + addon.subcat_name | replaceAll" [attr.data-multi_option]="addon.multi_option">

          <div class="px-3 mb-3 __{{addon.subcat_name | replaceAll}}" >

            <div
                    *ngFor="let subItem of addon.sub_item; let i = index"
                    class="custom-control border-bottom pl-4 pr-2 py-3"
                    [class.custom-checkbox]="addon.multi_option == 'multiple' || addon.multi_option == 'custom'"
                    [class.custom-radio]="addon.multi_option === 'one'"
            >

              <input
                      #inp
                      [type]="addon.multi_option === 'one' ? 'radio' : 'checkbox' "
                      class="custom-control-input"
                      [id]="'addon-' + addon.subcat_id+ '-' + subItem.sub_item_id"
                      name="{{'sub_item-'+ addon.subcat_id +'-'+ subItem.sub_item_id}}"
                      [value]="subItem.sub_item_id + '|' + subItem.price + '|' + subItem.sub_item_name + '|' + addon.calculate_type + '|' + addon?.subcat_id"
                      (change)="getAddonCheck($event, addon.subcat_id, inp.value)"
                      [attr.datatype]="addon.multi_option"
                      [checked]="verifySelect(addon.subcat_id, subItem.sub_item_name)"/>

              <label class="custom-control-label d-block" [for]="'addon-' + addon.subcat_id+ '-' + subItem.sub_item_id">
                {{subItem.sub_item_name}}
                <span class="text-muted small" [style.display]="subItem.price > 0 ? 'inline' : 'none' ">
                  + {{subItem.pretty_price}}
                </span>

                <span
                        *ngIf="addon.multi_option === 'multiple'"
                        class="count-number bg-white"
                        [style.display]="verifySelect(addon.subcat_id, subItem.sub_item_name) ? 'flex': 'none' ">
                  <button type="button" class="btn-sm left dec btn btn-outline-secondary count-number-minus" (click)="minusBtn($event, addonqty, '', addon.subcat_id, subItem.sub_item_id, subItem.price)"><i class="feather-minus"></i> </button>
                  <input
                          #addonqty
                          class="count-number-input addon_qty"
                          type="text"
                          readonly
                          [value]="getQtySubItemValue(addon.subcat_id, subItem.sub_item_name)">
                  <button type="button" class="btn-sm right inc btn count-number-plus" (click)="plusBtn($event, addonqty, '' , addon.max,addon.subcat_name.replaceAll(' ', ''), addon.subcat_id, subItem.sub_item_id, subItem.price)"> <i class="feather-plus"></i> </button>
                </span>

              </label>

              <small class="d-block text-muted">{{subItem.item_description}}</small>
            </div>

          </div>
        </div>
      </div>

      <div class="form-group p-3 border-top mt-2 mb-md-0 mb-5">
        <textarea class="form-control" name="notes" [(ngModel)]="notes" id="notes" placeholder="Observações para este item?" rows="3"></textarea>
      </div>

    </div>

    <div class="product-operator-footer" >
      <div class="counter-product-light"  *ngIf="this.configCatalog?.disableCheckout === '0'">
        <button type="button" class="subtract" name="minus" (click)="minusBtn($event, qtyTotal, 'total')">-</button>
        <div #qtyTotal name="qty" id="qty" [innerHTML]="qty"></div>
        <button type="button" name="plus" (click)="plusBtn($event, qtyTotal, 'total')" >+</button>
      </div>
      <div class="button-add"  *ngIf="this.configCatalog?.disableCheckout === '0'">
        <button #btn type="button" (click)="addToCart(formMenu, btn)" class="btn btn-primary roudned" [disabled]="clickCancel" class="btn primary-button-filled bg-primary">
          <div class="spinner-border" role="status" *ngIf="clickCancel">
            <span class="sr-only">Loading...</span>
          </div>
          {{txtBtn}} <span [innerHTML]="priceTotal | prettyPrice"></span>
        </button>
      </div>
    </div>
  </form>
</div>

<app-loading *ngIf="loading"></app-loading>
